import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';


function Header() {
    return (
        <header className='App header'>
            <h4 className='title'><strong>CARLA ANTUNES</strong> / Freckles Design</h4>
        </header>
    );
}

export default Header;
